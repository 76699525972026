import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import ModifierOptions from './ModifierOptions';

const ModifierItem = ({
  data,
  onClick,
  values = [],
  hasError,
  sizeTypeModifier,
  selectedSizeTypeModifier,
  cardData,
  selectedSizeType,
  handleSelectedSizeType,
  setFirstRender,
  firstRender,
}) => {
  const [disabled, setDisabled] = useState('');
  const [checked, setChecked] = useState('');
  const min = data.tags?.length ? 1 : data.min;
  const max = data.tags?.length ? 1 : data.max;

  useEffect(() => {
    const allOptions = data.list.map((i) => i._id);
    const selectedCount = allOptions.reduce(
      (acc, i) => (values.findIndex(({ _id }) => _id === i) !== -1 ? acc + 1 : acc),
      0
    );
    if (!max) {
      setDisabled(false);
    } else {
      setDisabled(selectedCount >= max);
    }
  }, [data.list, min, max, values]);

  function handleSelect(e, option) {
    const { value } = e.target;
    if (value === checked) {
      setChecked('');
    } else {
      setChecked(value);
    }
    const { _id, price, name, priceOverride } = option;
    onClick(
      {
        modifierId: data._id,
        _id,
        price,
        originalPrice: price,
        name,
        freeCount: data.free,
        priceOverride,
        tags: data.tags,
        isOnePrice: data.isOnePrice,
      },
      checked
    );
  }

  return (
    <div className="modifier px-3">
      <div className="modifier-desc py-3 mb-3 d-flex justify-content-between">
        <h5 className="modifier-title m-0">
          {data.name}{' '}
          {!!data.min && <small className={values.length >= data.min ? 'text-success' : 'text-danger'}>Required</small>}
        </h5>
        <div className={'modifier-description d-flex align-items-center'}>
          {!!data.min && (
            <span>
              Select at least: <b>{Math.max(data.min - values.length, 0)}</b>
            </span>
          )}
          {!!data.max && (
            <div className="ml-2">
              Select at most:{' '}
              <b>
                {data.max}/{values.length}
              </b>
            </div>
          )}
          {!!data.free && (
            <div className="ml-2">
              Free options: <b>{data.free}</b>
            </div>
          )}
        </div>
      </div>
      <RadioGroup name="options">
        {data.list.map((option, index) => {
          const copyObj = { ...option };
          if (selectedSizeTypeModifier?._id && !data.isOnePrice) {
            const foundPriceOverride = copyObj.priceOverride.find(
              (item) => item.context_value === selectedSizeTypeModifier?._id
            );
            if (foundPriceOverride) {
              copyObj.price = foundPriceOverride.price;
            }
          }

          return (
            <ModifierOptions
              key={`${copyObj._id}-${index}`}
              data={data}
              option={copyObj}
              max={max}
              handleSelect={handleSelect}
              values={values}
              hasError={hasError}
              sizeTypeModifier={sizeTypeModifier}
              cardData={cardData}
              selectedSizeTypeModifier={selectedSizeTypeModifier}
              selectedSizeType={selectedSizeType}
              handleSelectedSizeType={handleSelectedSizeType}
              firstRender={firstRender}
              setFirstRender={setFirstRender}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default ModifierItem;
