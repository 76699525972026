import instance, { setSessionId } from 'services/api';
import moment from 'moment-timezone';
import { MENU_LOADING, MENU_RES, MENU_ERROR, SET_ACTIVE_MENU } from './types';
import weekDays from 'constants/weekDays';
import { workingHoursConverter, specialHoursConverter, isWithinInterval } from 'helpers/workingHours';

export const getMenu = (restaurant) => (dispatch) => {
  dispatch({ type: MENU_LOADING });
  return instance
    .get(`/menu/website/${restaurant}`)
    .then(({ data: { data } }) => {
      if (!data?.business?.settings.hasOwnProperty('asap')) {
        data.business.settings.asap = {
          disableAsap: false,
          qty: null,
        };
      }

      if (!data.business?.timezone) {
        data.business.timezone = { timeZoneId: 'America/Los_Angeles' };
      }
      const timeZone = data.business?.timezone?.timeZoneId;
      const currentTime = moment().tz(timeZone).format('HH:mm');
      const currentWeekDay = moment().tz(timeZone).format('ddd').toLowerCase();
      const currentDate = moment().tz(timeZone).format('M/DD/yyyy');

      // data.business.specialDates.forEach((dateItem) => {
      //   const currentWeekDayItem = moment(dateItem.date).tz(timeZone).format('ddd').toLowerCase();
      //   data.business.workingHours[currentWeekDayItem].hours = dateItem.hours;
      //   data.business.workingHours[currentWeekDayItem].opened = dateItem.opened;
      // });
      data.business.initialWorkingHours = data.business.workingHours;

      data.business.workingHours = workingHoursConverter(data.business.workingHours);

      data.business.specialDates = specialHoursConverter(
        data.business.specialDates,
        data.business.workingHours,
        data.business.initialWorkingHours,
        timeZone
      );

      // specialHoursConverter(data.business.specialDates);
      // data.business.withoutConverted = data.business.workingHours;
      data.categories.forEach((category) => {
        let categoryDisable = [];
        const { availability } = category;
        if (availability.length) {
          availability.forEach((availabilityData, index) => {
            let { regularHours } = availabilityData;
            regularHours = workingHoursConverter(regularHours);
            const currentDayFromRegularHours = regularHours[currentWeekDay];
            const { hours, opened } = currentDayFromRegularHours;
            if (!opened) {
              return categoryDisable.push(true);
            }
            const statement = (hour) => {
              const { start, end } = hour;
              return !isWithinInterval(currentTime, start, end);
            };
            const needDisable = hours.every(statement);
            categoryDisable.push(needDisable);
          });
        } else {
          categoryDisable.push(false);
        }
        const found = categoryDisable.every((item) => item);

        category.disabled = found;
        category.products.forEach((item) => {
          item.modifiers = item.modifiers.filter((modItem) => modItem.list.length);
          return (item.disabled = found);
        });
      });

      // const copyCats = [...data.categories];
      const filteredWithDisabled = data.categories.filter((item) => item.disabled);
      const filteredWithoutDisabled = data.categories.filter((item) => !item.disabled);
      data.categories = [...filteredWithoutDisabled, ...filteredWithDisabled];
      if (data) {
        let closed = '';
        const todayIsHoliday = data.business.specialDates.find((specialDateItem) => {
          return moment(specialDateItem.date).isSame(currentDate);
        });
        if (!todayIsHoliday) {
          if (data.business.workingHours[currentWeekDay]) {
            const { opened, hours, timeApPm } = data.business.workingHours[currentWeekDay];
            if (!opened) {
              closed = 'Restaurant is closed for today.';
            } else {
              let isOpened = false;
              for (let index = 0; index < hours.length; index++) {
                const { start, end } = hours[index];
                if (isWithinInterval(currentTime, start, end) && !isOpened) {
                  isOpened = true;
                  break;
                }
              }

              if (!isOpened) {
                closed = `Restaurant is closed now! Working hours:  ${timeApPm}. All times in  ${data.business?.timezone?.timeZoneName}`;
              }
            }
          }
        } else {
          // Holiday & Special Hours

          if (data.business.specialDates.length) {
            const { hours, opened, timeApPm } = todayIsHoliday;
            if (!opened) {
              closed = ' Restaurant is closed for today.';
            } else {
              let isOpened = false;
              for (let index = 0; index < hours.length; index++) {
                const { start, end } = hours[index];

                if (isWithinInterval(currentTime, start, end) && !isOpened) {
                  isOpened = true;
                  break;
                }
              }
              if (!isOpened) {
                closed = `Restaurant is closed now! Working hours:  ${timeApPm}. All times in  ${data.business?.timezone?.timeZoneName}`;
              }
            }
          }
        }

        if (data.business.avatar?.url) {
          const favicon = document.querySelector("link[rel='apple-touch-icon']");
          const favicon1 = document.querySelector("link[rel='icon']");
          favicon1.setAttribute('href', data.business.avatar.url);
          favicon.setAttribute('href', data.business.avatar.url);
        }
        if (data.business.companyName) {
          document.title = `${data.business.companyName} | Orders.co`;
        }
        setSessionId(data?.business?._id);

        // TODO related to ASAP
        const { qty, disableAsap } = data.business.settings.asap;

        const startMinDate = moment
          .tz(data.business.timezone.timeZoneId)
          .add(Number(qty), 'm')
          .format('YYYY-MM-DD HH:mm');
        if (disableAsap) {
          const asapHours = moment(startMinDate).hours();
          const asapMinutes = moment(startMinDate).minutes();
          const asapDay = moment(startMinDate).format('ddd').toLowerCase();
          const wantedDay = data?.business?.workingHours[asapDay];
          for (let i = 0; i < wantedDay.hours.length; i++) {
            const wantedHour = wantedDay.hours[i].start.split(':')[0];
            const wantedMinute = wantedDay.hours[i].start.split(':')[1];
            const endHour = wantedDay.hours[i].end.split(':')[0];
            const endMinute = wantedDay.hours[i].end.split(':')[1];
            const startWorkingDay = moment
              .tz(data.business.timezone.timeZoneId)
              .set('h', wantedHour)
              .set('m', wantedMinute)
              .format('YYYY-MM-DD HH:mm');
            const endWorkingDay = moment
              .tz(data.business.timezone.timeZoneId)
              .set('h', endHour)
              .set('m', endMinute)
              .format('YYYY-MM-DD HH:mm');
            const sameDays = moment(moment(startWorkingDay).format('YYYY-MM-DD')).isSame(
              moment(startMinDate).format('YYYY-MM-DD')
            );

            const checkMinutes = Number(endMinute) >= asapMinutes && asapMinutes > 45;
            if (
              (moment(startMinDate).isSame(endWorkingDay) && moment(startMinDate).isAfter(endWorkingDay)) ||
              Number(wantedHour) >= asapHours
            ) {
              if (Number(wantedHour) <= asapHours) {
                data.business.workingHours[asapDay].hours[i].start = String(`${asapHours}:${asapMinutes}`);
                data.business.startMinDate = moment(startMinDate)
                  .set('h', asapHours)
                  .set('m', asapMinutes)
                  .format('YYYY-MM-DD HH:mm');
              } else {
                data.business.workingHours[asapDay].hours[i].start = String(`${wantedHour}:${wantedMinute}`);
                data.business.startMinDate = moment(startMinDate)
                  .set('h', wantedHour)
                  .set('m', wantedMinute)
                  .format('YYYY-MM-DD HH:mm');
              }
            } else if ((Number(endHour) <= asapHours || sameDays) && checkMinutes) {
              data.business.workingHours[asapDay].hours[i].start = String(`${wantedHour}:${wantedMinute}`);
              data.business.startMinDate = moment(startMinDate).add(1, 'd').startOf('d').format('YYYY-MM-DD HH:mm');
            } else {
              data.business.workingHours[asapDay].hours[i].start = String(`${asapHours}:${asapMinutes}`);
              data.business.startMinDate = startMinDate;
            }
          }
        }

        return dispatch({ type: MENU_RES, payload: { ...data, closed } });
      }
    })
    .catch((err) => {
      dispatch({ type: MENU_ERROR, payload: err?.response?.data });
      return Promise.reject(err);
    });
};

export const setActive = (id) => (dispatch, getState) => {
  const {
    Menu: { items },
  } = getState();
  const activeItem = items.find((x) => x._id === id);
  dispatch({ type: SET_ACTIVE_MENU, payload: activeItem });
};
