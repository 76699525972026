import React from 'react';
import { Box, Checkbox, FormControlLabel, Radio, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { formatPrice } from 'utils/utils';

const ModifierOptions = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');
  const isEditMode = mode === 'edit';
  const {
    data,
    option,
    max,
    handleSelect,
    hasError,
    values = [],
    sizeTypeModifier,
    selectedSizeTypeModifier,
    firstRender,
    setFirstRender,
    disabled,
  } = props;

  const isActive = values.find(({ _id }) => _id === option._id) || null;
  const checkShowOption = !sizeTypeModifier || selectedSizeTypeModifier || data?.isOnePrice;
  const checkClassName = `modifier-option ${
    !!isActive ? 'active' : disabled && max != 1 ? 'disabled' : hasError ? 'error' : ''
  }`;
  const checkFreeOption = isActive && isActive.price !== option.price && isActive?.freeCount;

  return (
    <>
      <Box sx={{ width: '100%', px: 0 }}>
        <Box className={checkClassName}>
          <FormControlLabel
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 0,
            }}
            control={
              <Box
                sx={{ p: '1rem', color: !checkShowOption ? 'rgba(0, 0, 0, 0.26)' : 'initial' }}
                className="item-name"
              >
                {option.name}
              </Box>
            }
            value={option.name.toLowerCase() + option.id}
            key={option._id}
            label={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                component="div"
              >
                <Typography
                  variant="h2"
                  fontWeight={400}
                  sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
                  mb={0}
                >
                  {checkShowOption ? (
                    <Box>
                      {(!isEditMode && checkFreeOption) ||
                      (checkFreeOption && isActive?.isFree) ||
                      (checkFreeOption && !firstRender) ? (
                        <>
                          <Box component="del" sx={{ color: '#E50C0C' }}>
                            +{formatPrice(option.price || 0)}
                          </Box>
                          +$0.00
                        </>
                      ) : (
                        <>+{formatPrice(option.price || 0)}</>
                      )}
                    </Box>
                  ) : null}
                </Typography>
                {max === 1 ? (
                  <Radio
                    sx={{
                      p: 0,
                      ml: 1.5,
                    }}
                    checked={!!isActive}
                    name="options"
                    onClick={(e) => {
                      setFirstRender(false);
                      return checkShowOption ? handleSelect(e, option) : null;
                    }}
                  />
                ) : (
                  <Checkbox
                    sx={{
                      p: 0,
                      ml: 1.5,
                    }}
                    disabled={!checkShowOption}
                    name="options"
                    checked={!!isActive}
                    onClick={(e) => {
                      setFirstRender(false);
                      return handleSelect(e, option);
                    }}
                  />
                )}
              </Box>
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default ModifierOptions;
