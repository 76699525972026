import React, { useMemo } from 'react';
import { formatPrice } from 'utils/utils';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { updateModalVisibility, updateProductModal } from 'redux/actions/ProductModal';
import { useDispatch } from 'react-redux';
// import { Card } /from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ProductItem = ({ data, restaurant }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const searchParams = new URLSearchParams(location.search);

  const showCustomizePrice = useMemo(() => {
    return data?.isOnePrice === false || data?.price == 0;
  }, [data.isOnePrice]);

  const handleProductItemClick = () => {
    dispatch(updateModalVisibility(true));
    dispatch(updateProductModal(data));
    localStorage.setItem('isModalOpen', JSON.stringify(true));
    searchParams.set('cid', data.category);
    searchParams.set('pid', data._id);
    history.push(`/${restaurant}?${searchParams.toString()}`);
  };

  return (
    <Card
      sx={{
        height: '142px',
        display: 'flex',
        backgroundColor: data?.disabled ? '#efefef' : '',
        [theme.breakpoints.down('md')]: {
          borderRadius: '0',
          boxShadow: 'none !important',
          borderTop: '1px solid #e7e7e7',
        },
        [theme.breakpoints.up('md')]: {
          border: '1px solid #e7e7e7',
          borderRadius: '5',
        },
      }}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          cursor: !data.disabled ? 'pointer' : '',
          flexDirection: 'row',
          [theme.breakpoints.up('md')]: {
            paddingLeft: '16px',
          },
          backgroundColor: 'transparent',
          '&::hover': {
            backgroundColor: 'trasparent !important',
            background: 'none',
          },
        }}
        elevation={0}
        onClick={!data.disabled ? handleProductItemClick : () => {}}
      >
        <CardContent sx={{ paddingLeft: 0, width: 'calc(100% - 142px)' }}>
          <Box>
            <Typography
              sx={{ fontWeight: 700, textOverflow: 'ellipsis', display: 'block' }}
              noWrap
              variant={'h6'}
              fontSize={'16px'}
              marginBottom={'4px'}
            >
              {data.name}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            component="span"
            fontSize="14px"
            lineHeight="20px"
            marginBottom="4px"
            maxHeight="40px"
            display="-webkit-box"
            sx={{
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
            }}
          >
            {data.description}
          </Typography>
          <Typography
            noWrap
            variant="subtitle2"
            color="text.primary"
            // component="div"
            fontSize="14px"
            lineHeight="20px"
            marginBottom="4px"
          >
            {showCustomizePrice ? 'Customize' : formatPrice(data.price)}
          </Typography>
        </CardContent>
        {data.image?.url && (
          <CardMedia
            component="img"
            image={`${
              data.image.url &&
              data.image.url.replace(process.env.REACT_APP_REPLACE, process.env.REACT_APP_REPLACE_WITH)
            }?tr=w-150,h-150)`}
            alt={data.name}
            sx={{
              width: 110,
              height: 110,
              borderRadius: '5px',
              flexShrink: '0',

              [theme.breakpoints.up('md')]: {
                height: '142px',
                width: '142px',
                borderRadius: '5',
              },
            }}
          />
        )}
      </Box>
    </Card>
  );
};

export default withRouter(ProductItem);
