import React, { useMemo, useEffect, useCallback } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateQty, deleteItem, deleteSharedItem, shareComplete } from 'redux/actions/cart';
import CheckoutForm from 'components/CheckoutForm';
import CheckoutFormShared from 'components/CheckoutFormShared';
import CardModal from 'components/CardModal';
import { Button, UncontrolledCollapse } from 'reactstrap';
import { createOrder } from 'redux/actions/order';
import { updateModalVisibility, updateProductModal } from 'redux/actions/ProductModal';
import { IconBack, IconCart, IconExpand, IconTrash } from 'components/Icons';
import CartItems from 'components/CardItem/index';
import CardSkeleton from 'components/CardSkeleton';
import { isNull } from 'lodash';
import { Box } from '@mui/material';

const getItemTotal = (options, price) => {
  let copyPrice = 0;
  const optValues = options && Object.values(options).flat();
  const optValueWIthTagType = optValues && optValues.find((item) => item.tags?.length);
  copyPrice = optValueWIthTagType ? optValueWIthTagType.price : price;

  for (const key in options) {
    const opt = options[key];
    opt.forEach((optionItem) => {
      if (!optionItem.tags?.includes('size') && optionItem.isOnePrice) {
        copyPrice += optionItem?.price;
      }

      if (!optionItem.isOnePrice && optionItem.originalPrice === optionItem.price) {
        const optionsValuesInFlat = Object.values(options).flat();
        const modifierWIthTagName = optionsValuesInFlat?.find((modifierItem) => modifierItem.tags?.length);
        const overrideObj = optionItem.priceOverride.find(
          (priceOverrideItem) => priceOverrideItem.context_value === modifierWIthTagName?._id
        );
        copyPrice += +overrideObj?.price;
      }
      if (optionItem.price !== optionItem.originalPrice && optionItem.price) {
        copyPrice += optionItem.price;
      }
    });
  }
  return copyPrice;
};

const getTaxTotal = (arr, settingsTax) => {
  let val = 0;
  arr.map(({ options, product, count }) => {
    let dataPrice = getItemTotal(options, product?.price) * count;
    if (!isNull(product.tax) && product.tax >= 0) {
      dataPrice = dataPrice * product.tax;
    } else {
      dataPrice = dataPrice * settingsTax;
    }
    return (val += dataPrice);
  });
  return val;
};

const getSubTotal = (arr) => {
  let val = 0;
  arr.map(({ options, product, count }) => {
    let dataPrice = getItemTotal(options, product?.price) * count;
    return (val += dataPrice);
  });
  return val;
};

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { restaurant } = useParams();
  const { user, settings, business, closed, closedDates } = useSelector(({ Menu }) => Menu);
  const { items, children, parent, loading } = useSelector(({ Cart }) => Cart);
  const { modalVisibility } = useSelector(({ ProductModal }) => ProductModal);
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get('cid');
  const productId = searchParams.get('pid');
  const uuid = searchParams.get('uuid');

  const handleChange = (id, count) => {
    if (count > 0) {
      dispatch(updateQty(id, { count }));
    } else {
      dispatch(deleteItem(id));
    }
  };

  useEffect(() => {
    if (categoryId && productId && uuid) {
      const productData = items.find((productItem) => {
        return productItem.uuid === uuid;
      });
      if (!productData) return;
      dispatch(updateModalVisibility(true));
      dispatch(
        updateProductModal({
          ...productData.product,
          modifiers: productData.options,
        })
      );
    } else {
      dispatch(updateModalVisibility(false));
    }
  }, [categoryId, dispatch, items, productId, uuid]);

  const handleRemove = (id) => {
    dispatch(deleteItem(id));
  };

  const handleRemoveShare = (e, id) => {
    e.stopPropagation();
    dispatch(deleteSharedItem(id));
  };

  const calcTotal = useMemo(() => {
    if (!items) return 0;
    let taxTotal = getTaxTotal(items, business.tax);
    let subTotal = getSubTotal(items);
    if (children.length) {
      children.forEach(({ items }) => {
        taxTotal += getTaxTotal(items, business.tax);
        subTotal += getSubTotal(items);
      });
    }
    return { taxTotal, subTotal };
  }, [children, items]);

  const closeModal = () => {
    history.push(`/${restaurant}/cart`);
    dispatch(updateModalVisibility(false));
    dispatch(updateProductModal({}));
  };

  const handleSubmit = (values) => {
    let products = items;
    children.forEach((i) => {
      products = [...products, ...i.items.map((j) => ({ ...j, instructions: `${i.name}: ${j.instructions}` }))];
    });

    return dispatch(
      createOrder({
        ...values,
        data: products.map((item) => ({
          ...item,
          productId: item.product._id,
          options: item.options ? Object.values(item.options).reduce((acc, val) => [...acc, ...val], []) : [],
        })),
        beReadyTZ: 'UTC',
        business: business._id,
      })
    );
  };

  const handleComplete = (values) => {
    shareComplete(values).then(() => {
      dispatch({ type: 'CART_EMPTY' });
      history.push({ pathname: `/${restaurant}/success`, state: { isShared: true } });
    });
  };

  return (
    <div className="cart-page">
      <div className="cart-page-content">
        <div className="cart-header">
          <Button color="link" tag={Link} to={`/${restaurant}`}>
            <IconBack /> Back To Menu
          </Button>
          <div className="cart-title-icon">
            <IconCart />
          </div>
        </div>

        {items.length || children.length ? (
          <>
            <div className="cart-body">
              <div className="cart-items">
                <CartItems
                  showActions
                  items={items}
                  onChange={handleChange}
                  onRemove={handleRemove}
                  restaurant={restaurant}
                  uuid={uuid}
                />
                {children.map((item, index) => (
                  <div key={item._id}>
                    <div className="py-3 border-top d-flex align-items-center">
                      <span className="mr-auto">{item.name}</span>
                      <Button aria-label="trash" className="mr-2 px-2" onClick={(e) => handleRemoveShare(e, item._id)}>
                        <IconTrash />
                      </Button>
                      <Button aria-label="expend" className="mr-2 px-2" id={`item_${index}`}>
                        <IconExpand height="24px" width="24px" />
                      </Button>
                    </div>
                    <UncontrolledCollapse toggler={`#item_${index}`}>
                      <CartItems items={item.items} restaurant={restaurant} />
                    </UncontrolledCollapse>
                  </div>
                ))}
              </div>
            </div>
            {!!user &&
              (parent ? (
                <CheckoutFormShared total={calcTotal} parent={parent} onSubmit={handleComplete} />
              ) : (
                <CheckoutForm
                  loading={loading}
                  total={calcTotal}
                  restaurant={restaurant}
                  onSubmit={handleSubmit}
                  settings={{ ...settings, ...business }}
                  asap={business.settings.asap}
                  tipSetting={business.settings.website}
                  closed={closed}
                  closedDates={closedDates}
                  owner={user}
                />
              ))}
          </>
        ) : (
          <Box sx={{ backgroundColor: '#ffffff' }} className="cart-body">
            {loading ? <CardSkeleton /> : <h4 className="text-muted text-center py-4">Cart is empty!</h4>}
          </Box>
        )}
      </div>
      {modalVisibility && uuid && (
        <CardModal
          modal={modalVisibility}
          closeModal={closeModal}
          uuid={uuid}
          categoryId={categoryId}
          productId={productId}
        />
      )}
    </div>
  );
};

export default Cart;
