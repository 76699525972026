import React, { useEffect, useState, useCallback, useRef } from 'react';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurants, setRestaurants, setRestaurantsLoading } from 'redux/actions/restaurants';
import EmptyRestaurant from 'assets/images/emptyRestaurant.png';
import instance from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PlacesAutocomplete from 'react-places-autocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormControlLabel, Typography } from '@mui/material';
import Footer from 'components/Footer';
import Restaurant from 'components/Restaurant/index';
import RestaurantMobile from 'components/Restaurant/mobile';
import BackgroundMarketplace from 'assets/images/GroupMarket.png';
import Logo from 'assets/images/logo.png';
import GooglePoweredBy from 'assets/icons/poweredByGoogle.svg';
import Check from 'assets/images/check.png';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Label } from 'reactstrap';

const Home = () => {
  const [address, setAddress] = useState('');
  const history = useHistory();
  const { restaurants, loadingRestaurant, error } = useSelector(({ Restaurants }) => Restaurants);
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const getLocationFromUser = () => {
    dispatch(setRestaurantsLoading(true));
    function success(pos) {
      const crd = pos.coords;
      let lat = crd.latitude;
      let long = crd.longitude;
      let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDYfjHl41cQl4BQZZ35YKPh-sHhL0Vv2ZM`;
      axios.get(url).then(({ data: { results } }) => {
        if (results.length) {
          getAddressInfo(results[0].formatted_address);
        }
      });
      dispatch(setRestaurantsLoading(false));
    }

    function error(err) {
      dispatch(setRestaurantsLoading(false));
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error);
  };

  const getAddressInfo = (addressData) => {
    geocodeByAddress(addressData).then((res) => {
      const {
        address_components,
        formatted_address,
        geometry: { location },
      } = res[0];

      const dataObj = {
        formattedAddress: formatted_address,
        geometry: location,
      };
      const components = address_components;
      if (components) {
        dispatch(getRestaurants(dataObj)).then(() => {
          setAddress(formatted_address);
          const height = document.getElementById('content');
          height.scrollIntoView();
          sessionStorage.setItem('userLocation', formatted_address);
        });
      }
    });
  };

  const handleSelect = (address) => {
    getAddressInfo(address);
    setAddress(address);
  };

  const handleChange = (val) => {
    setAddress(val);
  };

  useEffect(() => {
    const initialData = sessionStorage.getItem('userLocation');
    if (initialData) {
      getAddressInfo(initialData);
    } else {
      getLocationFromUser();
    }
  }, []);

  useEffect(() => {
    if (!history.location.pathname.includes('success')) {
      const blackBG = document.getElementById('black-background');
      if (blackBG) {
        blackBG.remove();
      }
    }
  }, [history.location.pathname]);

  return (
    <Box sx={{ backgroundColor: '#FCFCFC' }} className="addressSearch">
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 3, borderBottom: '3px solid #f79140' }}>
        <img src={Logo} alt="Orders.co Logo" />
      </Box>

      <Box>
        <Box
          sx={{
            px: 2,
            backgroundColor: '#232932',
            height: matchesSm ? '460px' : '717px',
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            shouldFetchSuggestions={address.length >= 2}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <>
                <Box className={'searchInput'}>
                  <label style={{ position: 'absolute' }} for="addressInput">
                    Type your address…
                  </label>
                  <TextField
                    {...getInputProps({
                      variant: 'outlined',
                      placeholder: 'Type your address…',
                      size: 'small',
                      sx: { width: '100%' },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Check} alt="address icon" />
                          </InputAdornment>
                        ),

                        sx: {
                          width: '100%',
                          borderRadius: '8px',
                        },
                      },
                    })}
                    id="addressInput"
                  />

                  <IconButton
                    aria-label="loading"
                    onClick={() => getAddressInfo(address)}
                    className="iconButton"
                    sx={{
                      width: '47px',
                      height: '47px',
                      borderRadius: '8px',
                      position: 'absolute',
                      right: 0,
                      backgroundColor: '#FF9140',
                      ':hover': {
                        backgroundColor: '#ffa767',
                      },
                    }}
                  >
                    {loadingRestaurant ? (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress size={20} />
                      </Box>
                    ) : (
                      <ArrowRightAltOutlinedIcon sx={{ color: 'white' }} />
                    )}
                  </IconButton>

                  <Box>
                    <List sx={{ backgroundColor: 'white', padding: 0, borderRadius: '8px' }}>
                      {suggestions.map((suggestion) => (
                        <ListItem key={uuidv4()}>
                          <ListItemButton sx={{ borderRadius: '8px' }} {...getSuggestionItemProps(suggestion)}>
                            <ListItemText primary={suggestion.description} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                      {suggestions.length ? (
                        <ListItem sx={{ textAlign: 'right' }} key={uuidv4()}>
                          <ListItemText
                            sx={{ textAlign: 'right' }}
                            primary={<img alt="google" src={GooglePoweredBy} />}
                          />
                        </ListItem>
                      ) : null}
                    </List>
                  </Box>
                  {/* </Box> */}
                </Box>
              </>
            )}
          </PlacesAutocomplete>

          <img className="marketplaceImg" src={BackgroundMarketplace} alt="food.orders.co hero image" />
        </Box>
        <Box sx={{ py: 14, display: 'flex', justifyContent: 'center' }} id="content">
          {/* <Tooltip title="You don't have permission to do this" followCursor>
            <Box sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>Disabled Action</Box>
          </Tooltip> */}
          {restaurants.length ? (
            <Container
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              {restaurants.map((item) =>
                matchesSm ? <RestaurantMobile key={uuidv4()} data={item} /> : <Restaurant key={uuidv4()} data={item} />
              )}
            </Container>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <img src={EmptyRestaurant} alt="empty list illustration" />
              {error && <Typography sx={{ mt: 2 }}>{error}</Typography>}
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', py: 3, backgroundColor: '#F2F2F2' }} className="footer">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
          <p>Powered by</p>
          <img src={Logo} alt="Orders.co Footer Logo" className="footerLogo" />
        </Box>
      </Box> */}
    </Box>
  );
};

export default Home;
