import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useOrderType = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { settings } = useSelector(({ Menu }) => Menu);
  const otFromStorage = sessionStorage.getItem('ot');
  const [otSelected, setOtSelected] = useState(otFromStorage);

  useEffect(() => {
    const otFromQueryString = searchParams.get('ot');
    if (otFromQueryString) {
      if (otFromQueryString === 'pickup' || otFromQueryString === 'takeout') {
        setOtSelected('pickup');
        sessionStorage.setItem('ot', 'pickup');
      } else {
        setOtSelected('delivery');
        sessionStorage.setItem('ot', 'delivery');
      }
    }
  }, [settings, searchParams]);

  return { otSelected: otSelected };
};

export default useOrderType;
