import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { AvField } from 'availity-reactstrap-validation';
import { FormGroup } from 'reactstrap';
import GooglePoweredBy from 'assets/icons/poweredByGoogle.svg';

const PlaceInputField = ({
  name,
  label,
  value,
  onChange,
  types = [],
  className = '',
  getAddressInfo,
  onBlur,
  onFocus,
  validateAddress,
  ...rest
}) => {
  const [address, setAddress] = useState(value ? value.formatted_address : '');
  const [addressInfo, setAddressInfo] = useState();

  const getAddress = (addressData, fromChange = false) => {
    geocodeByAddress(addressData).then((res) => {
      const { address_components, formatted_address } = res[0];
      const components = address_components;
      // const addressInfo = {
      //   address1: '',
      //   address2: '',
      //   city: '',
      //   state: '',
      //   country: '',
      //   zipCode: '',
      //   county: '',
      //   fullAddress: addressData,
      // };

      const addressInfo = {
        address1: '', // street_number + route
        address2: '', // subpremise
        city: '', // locality
        state: '', // administrative_area_level_1
        country: '',
        zipCode: '', // postal_code
        address: formatted_address,
        fullAddress: formatted_address,
        // geometry: {
        //   lat: geometry.location.lat(),
        //   lng: geometry.location.lng(),
        // },
      };

      if (components) {
        for (var i = 0, l = components.length; i < l; i++) {
          var component = components[i];
          if (component.types && component.types.indexOf('route') !== -1) {
            const streetNumber = components.find((item) => item.types.indexOf('street_number') !== -1);
            addressInfo.address1 = streetNumber
              ? `${streetNumber?.long_name} ${component.long_name}`
              : component.long_name;
          }
          if (component.types && component.types.indexOf('locality') !== -1) {
            addressInfo.city = component.long_name;
          }
          if (component.types && component.types.indexOf('administrative_area_level_1') !== -1) {
            addressInfo.state = component.short_name;
          }
          if (component.types && component.types.indexOf('country') !== -1) {
            addressInfo.country = component.long_name;
          }
          if (component.types && component.types.indexOf('postal_code') !== -1) {
            addressInfo.zipCode = component.long_name;
          }
          if (component.types && component.types.indexOf('subpremise') !== -1) {
            addressInfo.address2 = component.long_name;
          }
        }
      }

      addressInfo.fullAddress = `${addressInfo.address1} ${addressInfo.address2}, ${addressInfo.city}, ${addressInfo.state} ${addressInfo.zipCode}, ${addressInfo.country}`;
      if (!fromChange) {
        setAddress(addressInfo.address1);
      }
      setAddressInfo(addressInfo);
      validateAddress(addressInfo);
    });
  };

  const handleSelect = (address) => {
    getAddress(address);
    setAddress(address);
  };

  const searchOptions = {
    types,
    // componentRestrictions: {
    //   country: ['us'],
    // },
  };

  const handleBlur = () => {
    onBlur(addressInfo || address);
  };

  const handleChange = (val) => {
    setAddress(val);
    setAddressInfo({ address: val, fullAddress: val });
  };

  return (
    <PlacesAutocomplete
      value={address}
      onInput={handleChange}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      shouldFetchSuggestions={address.length >= 2}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <FormGroup className={className}>
          <AvField
            {...getInputProps({
              name,
              label,
              autoComplete: 'off',
              onBlur: handleBlur,
              onFocus: onFocus,
              ...rest,
            })}
          />
          <div className="place-autocomplete-dropdown">
            <div className={`dropdown-menu ${suggestions.length || loading ? 'd-block' : ''}`}>
              {loading && <div className="px-3 py-2 text-muted">Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.placeId}
                  className={`dropdown-item ${suggestion.active ? 'active' : ''}`}
                  {...getSuggestionItemProps(suggestion)}
                >
                  {suggestion.description}
                </div>
              ))}
              {suggestions.length ? (
                <div style={{ textAlign: 'right', marginRight: '12px' }} key={new Date()}>
                  <img alt="google" src={GooglePoweredBy} />
                </div>
              ) : null}
            </div>
          </div>
        </FormGroup>
      )}
    </PlacesAutocomplete>
  );
};
export default PlaceInputField;
